@import '../abstracts/util';

@mixin graphql-playground-base() {
  .graphql-playground {
    overflow-x: hidden;
    min-height: calc(100% - 4rem);

    button {
      text-transform: uppercase;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0.53px;
      font-size: 14px;
      margin-left: 6px;
      cursor: pointer;
      background: rgb(15, 32, 45);
      border-radius: 2px;
      flex: 0 0 auto;
      padding: 6px 9px 7px 10px;
      transition: background-color 0.1s linear 0s;
    }

    input {
      color: rgba(255, 255, 255, 0.6);
      font-size: 13px;
      background: rgb(15, 32, 45);
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(9, 20, 28);
      border-image: initial;
      padding: 6px 12px 6px 30px;
      flex: 1 1 0%;
    }

    svg + input {
      background: none;
      border: none;
      color: initial;
      padding: 0;
    }

    .CodeMirror * {
      font-family: 'Source Code Pro', 'Consolas', 'Inconsolata', 'Droid Sans Mono', 'Monaco',
        monospace !important;
    }

    > div,
    > div > div {
      height: 100%;
    }

    .playground > div:first-child {
      background: transparent;
      overflow: visible;

      // Tabs wrapper
      > div {
        // Tabs
        > div {
          margin-right: 2px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .graphiql-wrapper > div > div > div:nth-child(2) > div:nth-child(2) {
      // Tracing tray wrapper
      & > div:nth-child(5) {
        // Tracing button wrapper
        & > div:first-child {
          cursor: auto;
          pointer-events: none;

          // Tracing button
          span {
            display: none;
          }
        }

        // GraphQL not supported placeholder text
        & > div:nth-child(2) {
          display: none;
        }
      }
    }

    &.graphql-playground--loading {
      position: relative;
      opacity: 0.5;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

@import '../abstracts/util';

@mixin webshell-base() {
  .webshell-layout {
    .webshell-toolbar {
      padding: 5px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      small {
        margin-bottom: 0.5rem;
        font-size: 0.75rem;
      }
    }

    .webshell-toolbar-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > * {
        margin-bottom: 0.5rem;

        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }

      .run-as {
        display: flex;
        align-items: center;

        & > *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }

      button.run-as-btn {
        padding: 0.5rem;
        margin-right: 0;
      }

      .react-select__control {
        width: 12rem;

        .react-select__value-container {
          height: 34px;
        }
      }

      &-cancel {
        color: #989898;
        cursor: pointer;
      }

      .secret-input {
        height: 2.25rem;
      }

      &-document {
        width: 12.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: 1px dashed clr('font-primary');
        cursor: pointer;
      }

      .rap-manager .rap-target-container {
        padding: 0;
        font-size: 1rem;
      }

      &-popover {
        border-radius: 0.25rem;
        padding: 0.75rem;
        background: white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        input:not(:last-child) {
          margin-bottom: 0.75rem;
        }
      }
    }
  }

  .query--wrapper {
    height: calc(100vh - 14rem);
    min-height: 14rem;

    .gutter-vertical {
      cursor: ns-resize;
    }
  }

  .query--history {
    background-color: clr('webshell-bg');
  }
}

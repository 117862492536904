@import '../abstracts/util';

@mixin nav-base() {
  .upgrade-btn {
    display: flex;
    align-items: center;
    position: relative;
    background: white;
    padding: 0.5rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: normal;
    color: clr('font-secondary');
    transition: background 0.3s ease-out;

    &:after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background: transparent;
      border: 1px solid clr('font-secondary');
      border-radius: 0.2rem;
    }

    &:hover {
      background: #f2f0fc;
    }

    &:active {
      background: #d4cafc;
    }

    .logo {
      fill: rgb(56, 23, 158);
      width: 1.15rem;
      margin-right: 0.5rem;
    }
  }

  .header {
    font-size: 0.875rem;
    font-weight: 200;
    display: flex;
    align-items: center;
    background-color: clr('header-nav-bg');
    //must be at least 101 (see CON-215)
    z-index: 110;
    box-shadow: clr(header-shadow);

    .home-link {
      flex-shrink: 0;
      padding: 0 1rem;
      display: flex;
      align-items: center;

      &.disabled {
        pointer-events: none;
      }
    }

    .logo-wrap {
      margin-right: 0.5rem;
    }

    .fauna-logo-desktop {
      width: 18px;
      fill: white;
    }

    .dropdown-items {
      text-transform: initial;
      font-size: initial;
      letter-spacing: initial;
      line-height: initial;
    }

    .dropdown-value {
      padding: 0 1rem;
      display: flex;
      height: config('headerHeight');
    }

    a,
    .dropdown .dropdown-value {
      color: clr('white-soft');
    }

    .dropdown-item {
      color: clr('link');
    }

    .svg-inline--fa {
      font-size: 1rem;
    }

    .fa-chevron-down {
      font-size: 10px;
      // 0.25 align chevron to the vertical center
      // of the teams dropdown text
      margin: 0.25rem 0 0 0.5rem;
      opacity: 0.4;
      width: initial;
    }

    .fa-external-link-alt {
      path {
        fill: clr('stroke');
      }
    }
  }

  .header__right {
    align-items: center;
    margin: 0 0.5rem 0 auto;
    display: flex;
    height: 50px;

    .profile-email {
      color: white;
      margin-right: 1rem;
    }

    .upgrade-btn {
      margin-right: 1rem;
    }

    .upgrade-wrapper {
      height: 100%;
      margin-right: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-left: 1px solid #d7d7e7;
      border-right: 1px solid #d7d7e7;

      .plan-type-wrapper {
        color: white;
        font-size: 12px;
        padding: 0 1rem;

        .plan-type {
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }
  }
}

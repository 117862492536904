@mixin pricing-banner-base() {
  .PricingBanner {
    position: relative;
  }

  .PricingBanner__icon {
    path {
      color: clr('danger') !important;
    }
  }

  .PricingBanner__close-wrapper {
    padding: 0 8px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

@import '../abstracts/util';

@mixin well-base() {
  $iconSize: 3rem;

  .well {
    display: flex;
    flex-direction: row;
    padding: 1rem 1.5rem;
    flex: 1;

    .well__text {
      min-width: 0;
    }

    .well__title {
      font-weight: 500;
      color: inherit;
      padding: 0;
      color: clr('font-secondary');
    }

    .well__icon {
      display: none;
      align-items: center;

      .well__texticon {
        background: clr('primary');
        border-radius: 50%;
        color: clr('white');
        font-weight: bold;
        height: 1.75rem;
        margin: 4px 0;
        text-align: center;
        width: 1.75rem;
      }
    }

    svg,
    img {
      font-size: $iconSize;

      path {
        color: clr('font-secondary');
      }
    }
    .btn {
      margin: 0.5rem 0;
      max-width: 12rem;
      height: 2.75rem;
    }
    .shadow {
      width: $iconSize;
      background: #cad0e7;
      height: 0.75rem;
      border-radius: 100%;
      margin-top: 0.5rem;
    }

    &.well--muted {
      opacity: 0.7;
    }
    &.well--centered {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 2rem 1.5rem;
      .well__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
      }
      .btn {
        margin: 1rem auto 0 auto;
      }
    }
  }

  .well--textOnly {
    padding: 1rem;
  }
}

@mixin well-medium() {
  .well {
    .well__icon {
      display: flex;
      flex-direction: column;
      padding: 0.25rem 1.5rem 0 0;
    }
  }
}

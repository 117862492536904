@import '../abstracts/util';

@mixin console-drawer-base() {
  .drawer {
    z-index: 9000;
  }

  .console-drawer {
    background: #f9f9f9;
    height: auto;
    min-height: 100%;
    width: 100vw;

    &-header {
      display: flex;
      justify-content: flex-end;
      padding: 1.875rem;
    }

    &-body {
      padding: 0 1.5rem;
    }

    .console-drawer-header {
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem 1.5rem;
    }

    a {
      color: clr('font-secondary');
      cursor: pointer;

      svg {
        color: clr('font-secondary');
        width: 0.625rem;
        height: 0.625rem;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
    }

    &-close {
      cursor: pointer;
      padding: 0 0.375rem;
      border-radius: 0.25rem;
      transition: background 0.3s ease-out;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      &:active {
        background: rgba(0, 0, 0, 0.15);
      }
    }

    .content-header__title {
      color: #2c315f;
    }

    .rap-manager {
      align-self: flex-start;

      .rap-target-container {
        padding: 0;

        svg {
          color: #192580;
          width: 0.625rem;
          height: 0.625rem;
        }
      }
    }
  }
}

@mixin console-drawer-small() {
  .console-drawer {
    width: 480px;
  }
}

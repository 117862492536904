@import '../abstracts/util';

@mixin roles-form-base() {
  .role__form__content-header {
    background: none;

    .content-header__title,
    .content-header__title input {
      width: 100%;
      z-index: 9;
    }

    .content-header__tabs {
      position: initial;
      margin-bottom: -1rem;
    }
  }

  .role__form__actions {
    margin: 1rem 0 0.5rem 0;

    .btn {
      display: inline-block;
      margin-right: 0.5rem;
      min-width: 10rem;
    }
  }
}

@mixin roles-form-small() {
  .role__form__content-header {
    .content-header__title {
      max-width: 25rem;
    }

    .content-header__tabs {
      position: absolute;
      margin-bottom: 0;
    }
  }
}

@import '../abstracts/util';

@mixin icon-base() {
  .icon--hoverable {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    &:not(.disabled) {
      cursor: pointer;
    }

    &:hover {
      &:not(.disabled) {
        background: #f2f0f8;
      }
    }
  }
}

@import '../abstracts/util';

@mixin select-base() {
  .select-option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      margin-right: 0.25rem;
    }
  }

  .select-group {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 12px;

    &-label {
      font-size: 0.75rem;
      color: #717b80;
      font-weight: 400;
    }
  }

  .react-select__group:not(:last-child) {
    border-bottom: 1px solid #d3dce1;
  }

  // react-select overrides for the database select
  .database-select {
    .react-select__option {
      padding: 0px;
      .select-option.no-focus {
        background: clr('white');
      }
      .select-option {
        height: 45px;
        .select-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-grow: 1;
        }
        .select-option-label {
          padding: 10px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          overflow: auto;
          white-space: nowrap;
        }
        .select-divider {
          width: 1px;
          height: 30px;
          background-color: #d7d7e8;
        }
        .select-navigation {
          margin: 0px 10px;
          border-radius: 10px;
          color: clr('font-muted');
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            color: clr('link');
            background: clr('link-hover-bg');
          }
        }
      }
    }
    .react-select__option--is-selected {
      color: clr('white');
      background: clr('primary');
      .select-option.no-focus {
        background: clr('primary');
      }
      .select-option {
        .select-divider {
          background: clr('white');
        }
        .select-navigation {
          color: clr('white');
          &:hover {
            color: clr('primary');
            background: clr('white');
          }
        }
      }
    }
  }
}

@import '../abstracts/util';

$widths: 20;

@mixin sizing-base() {
  .width-auto {
    width: auto !important;
  }

  .min-height-100 {
    min-height: 100%;
  }

  @for $i from 0 through $widths {
    .width-#{$i*5} {
      width: #{$i * 5 * 1%} !important;
    }
  }
}

@mixin sizing-xsmall() {
  @for $i from 0 through $widths {
    .width-xsmall-#{$i*5} {
      width: #{$i * 5 * 1%} !important;
    }
  }
}

@mixin sizing-small() {
  @for $i from 0 through $widths {
    .width-small-#{$i*5} {
      width: #{$i * 5 * 1%} !important;
    }
  }
}

@mixin sizing-medium() {
  @for $i from 0 through $widths {
    .width-medium-#{$i*5} {
      width: #{$i * 5 * 1%} !important;
    }
  }
}

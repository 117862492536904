@import '../abstracts/util';

@mixin content-header-base() {
  .content-header {
    position: relative;
    padding: 0.5rem 1rem;
    margin: 0 -1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 3.25rem;

    &--divider {
      border-bottom: 1px solid clr('stroke');
    }

    &.SecurityTabs {
      padding-bottom: 0;
      .content-header__tabs {
        position: relative;
      }
    }

    .content-header__title {
      display: flex;
      flex-direction: column;
      margin: 0;
      font-size: 1rem;
      word-break: break-word;
      // when we have buttons inside of the title the line is bigger
      // so to uniform this size we need to set the line-height
      line-height: 1.8;

      > * {
        margin-left: 1rem;
      }
    }

    .content-header__links {
      display: flex;
      flex-direction: row;
      margin-left: 0;

      .btn {
        padding: 0.5rem 0;
        margin-left: 1rem;
      }

      .btn:first-of-type {
        margin-left: 0;
      }
    }

    .content-header__actions {
      margin-left: 0.5rem;

      .btn {
        margin-left: 0.25rem;
        float: left;
      }
    }

    .content-header__tabs {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &.content-header--no-divider {
      min-height: 3.25rem;
      border-bottom: 0;

      .content-header__actions {
        margin-left: 0;
      }
    }
  }

  .content-header + .card {
    margin-top: 0;
  }
}

@mixin content-header-xsmall() {
  .content-header {
    margin: 0 -1.5rem;
    padding: 0.5rem 1.5rem;

    .content-header__title {
      flex-flow: row wrap;
      align-items: center;
    }

    .content-header__links {
      .btn:first-of-type {
        margin-left: 1rem;
      }
    }
  }
}

@mixin content-header-small() {
  .content-header {
    background: none;
    padding: 0.5rem 0;
    min-height: auto;
    margin: 0;

    &.SecurityTabs {
      padding-bottom: 0.5rem;
    }

    .content-header__title {
      margin: 0;
      font-size: 1.25rem;

      > * {
        margin-left: 0;
      }

      .btn {
        margin-left: 0.25rem;
        padding: 0.5rem 1rem;

        &:first-child {
          margin-left: 0.5rem;
        }
      }
    }

    &.content-header--no-divider {
      .content-header__actions {
        margin-left: 0.5rem;
      }
    }
  }
}

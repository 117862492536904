@import '../abstracts/util';

@mixin spinner-base() {
  $bar-width: 6rem;

  @keyframes bar-spinner {
    0% {
      left: 0;
    }
    50% {
      left: calc(100% - #{$bar-width});
    }
    100% {
      left: 0;
    }
  }

  .spinner--bar {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    height: 4px;
    background-color: #e3e6ef;
  }

  .spinner--bar__bar {
    position: relative;
    left: 0;
    width: $bar-width;
    height: 100%;
    background-color: clr('primary');
    animation: bar-spinner 2s ease-in infinite;
  }
}

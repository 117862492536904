@import '../abstracts/util';

$margin: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 2rem
);

@mixin margin-base() {
  .no-margin {
    margin: 0 !important;
  }

  .margin-left-auto {
    margin-left: auto;
  }

  .margin-right-auto {
    margin-left: auto;
  }

  .margin-x-auto {
    margin: auto;
  }

  @each $size, $value in $margin {
    .margin-right-#{$size} {
      margin-right: $value;
    }

    .margin-left-#{$size} {
      margin-left: $value;
    }

    .margin-top-#{$size} {
      margin-top: $value;
    }

    .margin-bottom-#{$size} {
      margin-bottom: $value !important;
    }

    .margin-y-#{$size} {
      margin-bottom: $value;
      margin-top: $value;
    }

    .margin-x-#{$size} {
      margin-right: $value;
      margin-left: $value;
    }
  }
}

@mixin margin-small() {
  .margin-small-left-auto {
    margin-left: auto;
  }

  .margin-bottom-small-none {
    margin-bottom: 0 !important;
  }
}

@import '../abstracts/util';

@mixin table-flex-base() {
  .table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .scrollable-content {
      overflow: auto;
      height: 100%;
      flex-grow: 1;

      .flex-table:not(.root) {
        cursor: pointer;
      }
    }
  }

  .databases-panel__table .width-25 {
    width: auto !important;
  }

  div {
    .flex-table {
      display: flex;
      flex-direction: column;
      margin: 0 0.25rem 0.25rem 0.25rem;
      border: 1px solid #eaeaee;
      padding: 0.5rem;
      cursor: default;

      &:first-child {
        margin: 0.25rem;
      }

      &:not(.root) {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      }

      &.root .firstColumn {
        color: #84899e;
      }
    }

    .flex-table-card {
      display: flex;
      justify-content: space-between;
      border-spacing: 0;
      color: clr('font-muted');
      padding: 0.125rem 0.375rem;
      border-bottom: 1px solid #eaeaee;

      &:first-child,
      &:first-child .mobile-only {
        color: #525ebc !important;
        font-weight: 600;
      }

      &:not(&.root) .mobile-only {
        font-weight: normal;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .hovering {
      background: clr('link-hover-bg');
    }
  }

  .dbNameColumn {
    font-weight: app(font-bold);
    color: clr('link');
    padding: 0.5rem 0;
    span:nth-child(2) {
      text-align: right;
    }
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }

  .mobile-only {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #84899e !important;
    font-weight: 500;
  }

  .header-row {
    position: absolute;
    top: -9999px;
    left: -9999px;

    .flex-table:hover {
      background: white !important;
    }
  }

  .content {
    padding-left: 0;
  }

  .normal-text {
    color: clr('font-primary');
  }

  span.mobile-only {
    color: clr('link');
  }
}

@mixin table-medium() {
  .databases-panel__table .width-25 {
    width: 25% !important;
  }

  .mobile-only {
    display: none;
  }

  .content {
    flex: auto;
    overflow-y: hidden;
  }

  .header-row {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    font-size: 0.85rem;
  }

  div {
    .flex-table {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      margin: 0;
      padding: 0 1rem 0 0;
      border: none;

      &:not(.root) {
        box-shadow: none;
      }

      &:first-child {
        margin: 0;
      }

      &.root .firstColumn {
        font-weight: 500;
      }
    }

    .flex-table-card {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.75rem 0;
      border-bottom: 1px solid #eaeaee;

      &:last-child {
        border-bottom: 1px solid #eaeaee;
      }
    }

    .width-25 {
      width: 25% !important;
      flex-basis: 25% !important;
    }

    .selected {
      font-weight: app('font-bold');
      color: clr('link');
    }
  }

  .dbNameColumn {
    justify-content: left !important;
  }

  .firstColumn {
    padding-left: 1.5rem;
  }

  .tr-link-flex {
    border-left-width: 0.25rem !important;
    border-left: solid;
  }

  .flex-item {
    flex-grow: 1;
    flex-basis: 15%;
  }
}

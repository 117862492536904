@import '../abstracts/util';

@mixin tooltips-base() {
  .__react_component_tooltip {
    max-width: 300px;
  }
  .formField--tooltip {
    display: flex;
    align-items: center;
  }
  .tooltip-toggle {
    width: config(touch-min);
    height: config(touch-min);
    color: clr(font-tooltips);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import '../abstracts/util';

@mixin chart-base() {
  $height: 13.25rem;
  $legendHeight: 1.188rem;

  .beta-annouce-chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .chart {
    width: 100%;

    .chart-wrapper {
      @extend .center;
      position: relative;
      height: $height;

      &.chart-wrapper-compact {
        height: $height - $legendHeight;
      }
    }

    .chart-title {
      b {
        font-size: 0.85rem;
      }
    }

    .chart-legends {
      &.chart-legends-inline {
        display: flex;
        justify-content: center;

        .chart-legend {
          margin: 0;
          margin-right: 1rem;
          text-align: center;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.chart-legends-small {
        .chart-legend {
          font-size: 0.875rem;
        }
      }
    }

    .chart-legend {
      margin-top: 1rem;
      font-size: 0.875rem;
    }

    &.chart-pie {
      padding: 1rem;

      .chart-title {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        * {
          text-align: center;
        }

        &.chart-title-big {
          font-size: 1.8rem;
          font-weight: 400;
        }
      }
    }

    &.chart-pie-legend-inline {
      display: flex;
      justify-content: center;
      align-items: center;

      .chart-wrapper {
        width: 7.5rem;
      }

      .chart-legend {
        margin-left: 1rem;
        margin-top: 0;
      }
    }

    &.chart-small {
      padding: 0.5rem;

      .chart-wrapper {
        height: 8rem;

        .chart-title {
          font-size: 0.875rem;
        }
      }
    }
  }
}

@mixin chart-small() {
  .chart {
    .chart-wrapper {
      position: relative;
    }

    .chart-legend {
      font-size: 1rem;
    }

    &.chart-pie {
      .chart-title.chart-title-big {
        font-size: 1rem;
      }
    }

    &.chart-pie-legend-inline {
      .chart-legend {
        margin-left: 1.5rem;
      }
    }
  }
}

@mixin chart-medium() {
  .chart.chart-pie {
    .chart-title.chart-title-big {
      font-size: 1rem;
    }
  }
}

@mixin chart-large() {
  .chart.chart-pie {
    .chart-title.chart-title-big {
      font-size: 1.6rem;
    }
  }
}

@import '../abstracts/util';

@mixin progress-base() {
  .progress {
    width: 100%;
    margin-bottom: 1rem;

    .progress-header {
      display: flex;
      margin-bottom: 0.5rem;

      .progress-title {
        font-size: 0.875rem;

        svg {
          font-size: 0.75rem;
          margin-right: 0.5rem;
          cursor: pointer;
        }
      }

      .progress-info {
        font-size: 0.75rem;
        color: clr('font-muted');
        margin-left: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .progress-bar {
    width: 100%;
    background-color: clr('stroke');
    height: 1rem;

    .progress-bar-fill {
      height: 100%;
      background-color: clr('primary');
      max-width: 100%;
      transition: width 0.25s ease-in-out;
    }

    &--xs {
      height: 0.45rem;
    }
  }
}

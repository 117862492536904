@import '../abstracts/util';

@mixin moduleRow-base() {
  .moduleRow {
    .card {
      // It causes an infinity width on the Dashboard Chart
      // width: 100%;
      &:last-child {
        //hide helpful resources on mobile. user can go to the docs
        display: none;
      }
    }
  }
}

@mixin moduleRow-small() {
  .moduleRow {
    display: flex;
    justify-content: space-between;
    .card {
      width: 49%;
      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }
}

@mixin moduleRow-medium() {
  .moduleRow {
    display: flex;
    justify-content: flex-start;
    .card {
      width: 33.333%;
      margin-right: 0.5rem;

      &:nth-child(2) {
        margin-right: 0.5rem;
      }

      &:last-child {
        display: block;
        margin-right: 0;
      }
    }
  }
}

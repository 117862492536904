@import '../abstracts/util';

@mixin otp-input-base() {
  .otp-container {
    display: flex;
    justify-content: center;
  }

  .otp-input {
    width: 3rem !important;
    height: 3rem;
    font-size: 2rem;
    border-radius: 4px;
    margin: 2px;
    &--error {
      border: 1px solid clr('danger') !important;
    }
  }

  .otp-invalid-message {
    color: clr('danger');
    padding-top: 1rem;
  }

  .mfa-setup-container {
    display: flex;
  }
}

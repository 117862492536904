@import '../abstracts/util';

@mixin stroke-base() {
  .stroke-bottom {
    border-bottom: 1px solid clr(stroke);
  }
  .stroke-top {
    border-top: 1px solid clr(stroke);
  }
  .stroke-right {
    border-right: 1px solid clr(stroke);
  }
  .stroke-left {
    border-left: 1px solid clr(stroke);
  }
}

@mixin stroke-small() {
  .stroke-sm-right {
    border-right: 1px solid clr(stroke);
  }
  .stroke-sm-bottom {
    border-bottom: 1px solid clr(stroke);
  }
  .stroke-sm-top {
    border-top: 1px solid clr(stroke);
  }
  .stroke-sm-left {
    border-left: 1px solid clr(stroke);
  }
}

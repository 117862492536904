@import '../abstracts/util';

@mixin region-select-base() {
  .react-select__single-value {
    .region-select-label {
      display: none;
    }
  }

  .react-select__option--is-selected {
    .region-select-label {
      color: #e6e6e6;
    }
  }

  .region-select-label {
    font-size: 0.75rem;
    color: #717b80;
    font-weight: 400;
  }
}

@import '../abstracts/util';

$spacing: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 2rem,
  6: 2.5rem,
  7: 3rem,
  8: 3.5rem
);

@mixin spacing-base() {
  .no-padding {
    padding: 0;
  }

  .no-padding-bottom {
    padding-bottom: 0;
  }

  .no-padding-top {
    padding-top: 0;
  }

  @each $size, $value in $spacing {
    .padding-#{$size} {
      padding: $value;
    }

    .padding-x-#{$size} {
      padding-left: $value !important;
      padding-right: $value !important;
    }

    .padding-y-#{$size} {
      padding-top: $value;
      padding-bottom: $value;
    }

    .padding-x-#{$size} {
      padding: 0 $value;
    }

    .padding-left-#{$size} {
      padding-left: $value !important;
    }

    .padding-right-#{$size} {
      padding-right: $value !important;
    }

    .padding-top-#{$size} {
      padding-top: $value !important;
    }

    .padding-bottom-#{$size} {
      padding-bottom: $value !important;
    }

    .margin-left-#{$size} {
      margin-left: $value !important;
    }

    .margin-right-#{$size} {
      margin-right: $value !important;
    }

    .margin-top-#{$size} {
      margin-top: $value !important;
    }

    .margin-bottom-#{$size} {
      margin-bottom: $value !important;
    }
  }
}

@mixin spacing-small() {
  @each $size, $value in $spacing {
    .padding-x-small-#{$size} {
      padding-left: $value;
      padding-right: $value;
    }
  }
}

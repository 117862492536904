@import '../abstracts/util';

@mixin toast-base() {
  .toast-content {
    font-size: 0.875rem;
    padding: 12px 6px;

    h3,
    p {
      font-size: 0.875rem;
      margin: 0;
    }

    h3 {
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
  }
}

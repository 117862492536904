@import '../abstracts/util';

@mixin code-base() {
  .code-editor,
  .ace_editor {
    * {
      font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important;
    }
  }

  .code-editor {
    color: rgb(248, 248, 242);

    .ace_selection {
      background: clr('code-hightlight') !important;
    }

    &--placeholder {
      padding: 1rem;
      height: 320px;
      background-color: #272936;

      .placeholder {
        margin-bottom: 0.75rem;
        background-color: clr('primary');
      }
    }
  }

  .json-display-container {
    background-color: clr(json-display-bg);
    min-height: 350px;
    width: 100%;
    // height 100% does not work if the parent element is using only min-height.
    // To have this working, you need to set on parent height: 1px.
    height: 1px;
  }

  .json-display {
    background-color: clr(json-display-bg);

    * {
      color: clr(json-display-fg);
    }

    .ace_hidden-cursors {
      opacity: 0;
    }
  }

  .code-editor-placeholder {
    width: 100%;
    height: 320px;
    background-color: #282a36;
  }

  .code-snippet {
    font-family: 'Source Code Pro', monospace !important;
    color: rgb(248, 248, 242);
    background-color: #282a36;
    padding: 1rem;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    white-space: break-spaces;

    &.code-snippet--light {
      background: #eeeff3;
      color: #222;
    }

    code {
      font-family: inherit;
    }
  }

  .ace_marker-layer {
    .metrics_marker {
      width: 100% !important;
      background: clr('query-metric-bg');
    }
  }

  .ace_tooltip,
  .ace_autocomplete * {
    color: clr('font-primary') !important;
  }

  .ace_dark.ace_editor.ace_autocomplete {
    background: clr('white');
  }

  .ace_dark.ace_editor.ace_autocomplete .ace_marker-layer .ace_active-line {
    background-color: #cad6fa;
  }

  .ace_dark.ace_editor.ace_autocomplete .ace_line-hover {
    border: 1px solid #abbffe;
    background: rgba(233, 233, 253, 0.4);
  }

  // Custom scrollbar
  // Chrome doesnot understand this is using a dark background
  .ace_editor.ace_dark {
    ::-webkit-scrollbar {
      background: none;
      width: 14px;
      height: 14px;
    }

    ::-webkit-scrollbar-thumb {
      border: solid 0 rgba(0, 0, 0, 0);
      border-right-width: 4px;
      border-left-width: 4px;
      -webkit-border-radius: 9px 4px;
      -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3),
        inset 0 0 0 4px rgba(255, 255, 255, 0.3);
    }

    ::-webkit-scrollbar-track-piece {
      margin: 4px 0;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      border-right-width: 0;
      border-left-width: 0;
      border-top-width: 4px;
      border-bottom-width: 4px;
      -webkit-border-radius: 4px 9px;
    }

    ::-webkit-scrollbar-thumb:hover {
      -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5),
        inset 0 0 0 4px rgba(255, 255, 255, 0.5);
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    .ace_scrollbar-h {
      margin: 0 2px;
    }
  }

  .ace_tooltip {
    border-radius: config('borderRadius');
  }

  .ace_info {
    background-image: url(../../images/info-circle-solid.svg) !important;
    background-position: 3px center !important;
    background-size: 14px !important;
    cursor: pointer;
  }

  .ace-search {
    color: #666 !important;
  }

  .ace_search_field {
    display: inline !important;
    padding: 0 6px !important;
    min-width: 17em !important;
    font-size: inherit !important;
    line-height: inherit !important;
    border: 1px solid #cbcbcb !important;
  }
}

@import '../abstracts/util';

@mixin addons-base() {
  table.addon-db-admin {
    display: table;

    & tbody {
      display: table-row-group;
    }

    & tr {
      display: table-row;
      margin: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px;
      border-bottom-style: solid;
      width: 100%;
    }

    & td {
      display: table-cell;
      padding: 0;
      margin: 0;
      width: 50%;
    }

    & td.addon-project-name {
      padding-right: 20px;
      padding-top: 27px;
      text-align: right;
      vertical-align: top;

      &.blocked {
        vertical-align: middle;
        padding-top: 0;
      }
    }
  }

  ul.addon-db-selector {
    width: 325px;
    padding-inline-start: 20px;
    list-style-type: none;
    & li {
      padding: 0;
      border: none;

      &.smaller {
        padding-left: 10px;
        padding-right: 10px;
      }

      & select {
        width: 300px;
      }
    }
  }

  .addon-install-footer {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .addon-install-actions-container {
    padding: 0;
  }

  .addon-install-actions {
    margin: 0;
  }
}

@import '../abstracts/util';

@mixin dropdown-base() {
  .dropdown {
    display: inline-block;
    position: relative;
    user-select: none;

    .dropdown-value {
      color: clr('link');
      cursor: pointer;
      display: flex;
      align-items: center;
      .dropdown-chevron {
        margin-left: 0.25rem;
        line-height: 0.5rem;

        svg {
          height: 0.5rem;
        }
      }
    }

    .dropdown-items {
      border-radius: config('borderRadius');
      display: none;
      position: absolute;
      background: clr('white');
      padding: 0.25rem 0;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      min-width: 8rem;
      margin: 0;
      z-index: 202;
    }

    .dropdown-item {
      width: 100%;
      text-align: left;
      font-weight: 300;
      display: block;
      text-decoration: none;
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      cursor: pointer;
      white-space: nowrap;

      svg {
        margin-right: 0.75rem;
        width: 2.75rem;
      }

      &:hover {
        background: clr('content-bg');
      }
    }

    .dropdown-item--profile {
      min-width: 220px;
      color: clr('link');
      padding: 0.75rem;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1.2;
      border-bottom: 1px solid clr('soft-stroke');
      margin-top: -4px;
      margin-bottom: 4px;

      &:hover {
        background: clr('content-bg');
      }
    }

    .dropdown-item--profile__avatar {
      margin-right: 0.75rem;
      color: clr('font-muted');
    }

    .dropdown-item--profile__avatar > * {
      width: 2.75rem;
      height: 2.75rem;
    }

    .dropdown-item--profile__name {
      display: block;
      font-weight: 400;
    }

    .dropdown-item--profile__email {
      font-size: 0.875rem;
    }

    .dropdown-item--settings {
      display: flex;
      padding: 0;

      .dropdown-item__label {
        flex: 1;
        color: clr('link');
        padding: 0.5rem 0.75rem;
        display: flex;
        align-items: center;
        font-size: inherit;
      }

      .dropdown-item__settings {
        display: flex;
        align-items: center;
        color: clr('stroke');

        &:hover {
          color: clr('dark-stroke');
        }
      }

      .dropdown-item__icon {
        font-size: 0.875rem;
        margin-right: 0 !important;
      }
    }

    .dropdown-item--action {
      color: clr('link');
      display: flex;
      align-items: center;

      .dropdown-item__icon {
        margin-left: auto;
      }

      svg {
        font-size: 0.875rem;
        // Nullify right padding to align the icon to the right correctly
        margin-right: -0.75rem;
      }
    }

    hr {
      background-color: clr('soft-stroke');
      margin: 0.25rem 0;
    }

    &.active .dropdown-items {
      display: initial;
    }

    &.dropdown-right .dropdown-items {
      right: 0;
      margin: 0;
    }

    &.dropdown-button {
      .dropdown-chevron {
        svg {
          margin: 0;
        }
      }

      .dropdown-items {
        margin: 0;
      }
    }

    &:hover,
    &.active {
      background: rgba(255, 255, 255, 0.1);

      &.light {
        background: clr('link-hover-bg');
      }
    }

    &--inline.active {
      .dropdown-items {
        display: flex;
        min-width: auto;
        padding: 0.25rem;
      }
    }
  }

  .dropdown__loading {
    line-height: 1;
    padding: 0 1.25rem;
  }
}

@mixin dropdown-small() {
  .dropdown {
    .dropdown-value {
      .dropdown-chevron {
        height: 0.75rem;
      }
    }

    .dropdown-items {
      min-width: 10rem;
    }

    &.dropdown-sm {
      font-size: 0.875rem;

      .dropdown-value {
        .dropdown-chevron {
          margin-left: 0.25rem;
          height: 0.5rem;
          line-height: 0;
        }
      }
    }
  }
}

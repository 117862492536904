@mixin ProviderRoleCard-base() {
  .ProviderRoleCard {
    &__chevron {
      margin-right: 0.5em;
      width: 0.6em;
    }

    .ace_editor {
      min-height: 100px;
    }
  }
}

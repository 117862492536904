@import '../abstracts/util';

@mixin panel-base() {
  .panel {
    background: clr('white-soft');
    padding: 0;

    .panel__header {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      min-height: config(headerHeight);

      .panel__header__actions {
        margin-left: auto;
      }
    }

    .scrollList {
      height: 100%;
    }
  }
}

@mixin panel-medium() {
  .panel {
    width: 17rem;
    padding: 0;
    border-right: 1px solid clr('soft-stroke');
    height: 100%;
    overflow-y: auto;
  }
}

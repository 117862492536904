@import '../abstracts/util';

@mixin placeholder-base() {
  .placeholder {
    background-color: #e3e7ef;
    border-radius: config('borderRadius');
    display: inline-block;

    &--block {
      display: block;
    }

    &--text {
      height: 0.5rem;
    }

    &--w-sm {
      width: 4rem;
    }

    &--w-md {
      width: 8rem;
    }

    &--w-lg {
      width: 16rem;
    }
  }
}

@import '../abstracts/util';

@mixin color-variables {
  /* 
  Make scss color variables accessible via css variables so that it can be used outside of a mixin 
  i.e. instead of `background-color: clr('primary')` it's possible to use `background-color: var(--color-primary)` 
  */
  @each $key, $value in map-get($theme, colors) {
    --color-#{$key}: #{$value};
  }
}

@import '../abstracts/util';

@mixin tabs-base() {
  .tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    border-bottom: 1px solid clr('stroke');

    .tabs__item {
      display: block;
      padding: 1rem;
      cursor: pointer;
      font-size: 0.875rem;
      text-transform: uppercase;
      color: clr('tab-color');

      &.tabs__item--active {
        border-bottom: 3px solid clr('tab-color');
      }
    }

    &.tabs--right {
      justify-content: flex-end;
    }
  }
}

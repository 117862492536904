@import '../abstracts/util';

@mixin tip-base() {
  .tip {
    background: clr('white');
    padding: 1rem;
    outline: 1px solid clr(soft-stroke);
    border-left: 5px solid clr('primary');

    .tip__label {
      text-transform: uppercase;
      font-weight: 600;
      color: clr('primary');
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;

      .spinner {
        height: 0.875rem;
        margin-right: 0.25rem;
      }

      &.no-margin {
        margin-bottom: 0;
      }
    }

    .tip__title {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5rem;

      &.wrapped {
        overflow-wrap: break-word;
      }
    }

    .tip__text {
      line-height: 1.6;
      max-width: 56rem;

      &:last-child {
        margin-bottom: 0;
      }

      &--sm {
        max-width: 38rem;
      }
    }

    a:not(.btn) {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 600;
    }

    &.tip--warning {
      border-left: 5px solid clr('tip-warning');

      .tip__label {
        color: clr('tip-warning');
      }
    }
  }
}

@mixin tip-small() {
  .tip {
    padding: 2rem;
  }
}

@import '../abstracts/util';

@mixin info-box-base() {
  .info-box-wrapper {
    background: white;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
  }

  .info-box {
    display: flex;
    padding: 0.5rem;
    border-radius: 0.5rem;

    &.primary {
      border: 1px solid clr('primary');
      background-color: rgba(clr('primary'), 0.05);
    }

    &.warning {
      // border: 1px solid clr('warning');
      // background-color: rgba(clr('warning'), 0.05);

      // Keeping these hardcoded styles for the time being so the look
      //  and feel of existing info boxs is not changed. We should move to
      //  the above color scheme in time for the sake of consistency.
      color: #8a5404;
      background: #fcf6ed;
      border: 1px solid #8a5404;
    }

    &.danger {
      border: 1px solid clr('danger');
      background-color: rgba(clr('danger'), 0.05);
    }

    > *:first-child {
      margin-right: 0.375rem;
    }

    svg {
      width: 1rem;
      height: 1rem;

      &.primary {
        color: clr('primary');
      }

      &.danger {
        color: clr('danger');
      }
    }
  }
}

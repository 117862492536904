@import '../abstracts/util';

@mixin toggle-base() {
  .toggle {
    .toggle__label {
      font-weight: 600;
      color: clr('font-secondary');
      display: flex;
      cursor: pointer;
      user-select: none;

      svg {
        margin-right: 0.5rem;
      }
    }

    .toggle__content {
      padding: 0 0 0.05rem 1rem;
      display: none;
      border-left: 1px solid clr('toggle-content-border');
      margin: 0.5rem 0 0 0.2rem;
    }

    &.toggle--open {
      .toggle__label {
        svg {
          transform: rotate(90deg);
        }
      }

      .toggle__content {
        display: inherit;
      }
    }
  }
}

@import '../abstracts/util';

@mixin auth-base() {
  .auth {
    color: clr(font-primary);
    min-height: 100vh;
    background: clr('auth-bg');
    display: flex;
    flex-direction: column;
    align-items: center;

    .auth-logo path {
      fill: clr('link');
    }
    a {
      text-decoration: none;
    }
    .verbiage-extra {
      display: none;
      p {
        padding-left: 1.25rem;
      }
      li span a {
        white-space: nowrap;
      }
    }
    .auth-mobile-header {
      display: block;
      margin-bottom: 1rem;
    }
    h1 {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 130%;
      text-align: center;
      a {
        white-space: nowrap;
      }
    }
    h3 {
      display: flex;
      text-align: center;
      font-size: 3vw;
      line-height: 140%;
      span {
        display: flex;
        flex-wrap: wrap;
        padding: 0 2vw;
        border-right: 1px solid #ccc;
        align-items: center;
        justify-content: center;
        width: 33.33333%;
      }
      span:last-child {
        border-right: 0;
      }
    }
    p,
    ul {
      line-height: 1.25rem;
      font-weight: 300;
      margin-bottom: 0;
    }

    p {
      line-height: 1.5rem;
    }

    ul {
      margin: 1rem 0 0 0;
      padding-left: 1.25rem;
      li {
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }
    .auth-logo-wrapper {
      position: relative;
      right: 0.5%;
      margin-bottom: 1rem;
    }
    .auth-logo {
      height: 2.25rem;
      position: relative;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
    .form-checkbox {
      padding: 0.5rem 0;
    }
    .auth-content {
      @extend .center;
      padding: 1.5rem 0 0.5rem 0;
      flex: 1;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;

      input,
      .btn {
        height: 2.75rem;
      }

      .auth-content-background {
        padding: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 100vw;

        &--sign-up {
          background-image: url('../../images/signup-pattern.png');
        }

        &--sign-in {
          background-image: url('../../images/signin-pattern.png');
        }
      }

      .auth-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: clr('white');
        box-shadow: clr('card-shadow');

        &--sign-in,
        &--invite {
          padding: 1rem;
        }

        &--sign-in,
        &--sign-up {
          width: 25rem;
          max-width: 100%;
        }

        .auth-info {
          padding: 0 2rem;
          text-align: center;
          margin-top: 1rem;
          img {
            width: 100%;
            max-width: 18rem;
            margin: 2rem auto 0 auto;
            display: block;
          }

          h1 {
            margin: 0 0 1.5rem;
          }
        }

        .auth-form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: config('borderRadius');
          background-color: clr('white');
          width: 100%;
          text-align: center;
          margin: 0 auto;
          [class^='verbiage'] {
            margin-bottom: 2rem;

            &.minimal {
              margin-bottom: 1.5rem;
              margin-top: 0.5rem;
            }
          }
          .auth-logo {
            margin: 0 auto 1rem;
            display: block;
          }

          label {
            text-align: left;
          }

          .auth-sign-up-link {
            margin-top: 0.5rem;
            display: block;
            color: clr('white');
            a {
              padding: 1rem 0.25rem;
            }
          }

          .auth-extra-link {
            color: clr('white');

            a {
              font-weight: 700;
            }
          }

          .sign-in-divider {
            margin: 0.75rem 0rem 0.5rem 0rem;
          }

          button:not(.legacy) {
            margin-top: 1.25rem;
          }
          .have-account,
          .no-have-account,
          .need-help {
            height: 2.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.5rem;
            font-weight: 300;
          }

          .password-label {
            display: flex;
            .forgot-password {
              font-size: 0.875rem;
            }
            a {
              margin-left: auto;
            }
          }
        }
      }

      .auth-icon {
        font-size: 5rem;
        path {
          color: clr('white');
        }
      }
    }

    .auth-footer {
      display: flex;
      align-items: center;
      padding: 1.5rem;
      opacity: 0.7;
      text-align: center;

      a {
        color: clr('primary');
      }

      small {
        font-weight: 200;
      }

      svg {
        width: 1rem;
        margin-right: 0.5rem;
      }
    }

    &::before {
      background: url('../../images/sf-preload.jpg');
    }

    .auth-loader {
      color: clr('white');
      flex-grow: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  .multi-fa-modal {
    .modal {
      max-width: 25rem;
      &__body {
        .otp-container {
          background-color: clr('primary-with-opacity');
          display: flex;
          justify-content: center;
          height: 100px;

          &--error {
          }
        }

        .backup-logo {
          background: clr('white') url('../../images/backup-codes.svg') center bottom no-repeat;
          height: 89px;
        }

        .backup-codes {
          font-family: 'Source Code Pro', monospace !important;
        }

        .otp-input {
          opacity: 100%;
          width: 40px !important;
          height: 40px;
          font-size: 2rem;
          border-radius: 4px;
          margin: 2px;
          &--error {
            border: 1px solid red !important;
          }
        }

        .bottom-note {
          height: 70px;

          &--error {
            color: red;
          }
        }
      }
    }
  }

  .verbiage--left {
    text-align: left;

    h1 {
      text-align: left;
    }
  }
}

@mixin auth-xsmall() {
  .auth {
    h3 {
      font-size: 2.5vw;
    }
  }
}

@mixin auth-small() {
  .auth {
    .auth-logo path {
      fill: clr('white');
    }
    [class^='verbiage'] {
      color: clr(font-primary);
    }
    .auth-mobile-header {
      display: none;
    }
    .auth-info {
      display: block;
    }
    .auth-logo {
      top: 0;
    }
    .verbiage-extra {
      display: flex;
      flex-direction: column;
    }
    h3 {
      font-size: 1.3vw;
      span {
        padding: 0.5rem;
        &:nth-child(2) {
          &:after {
            position: relative;
            top: 0.8vw;
            padding-left: 0.2vw;
          }
        }
      }
    }
    a {
      color: clr('link');
      font-weight: 400;
    }
    hr {
      margin: 1rem 0;
      width: 3rem;
    }

    .auth-content {
      .auth-content-background {
        padding: 72px;

        &.minimal {
          padding: 0;
        }
      }

      .auth-content-wrapper {
        display: flex;
        flex-direction: row;

        .auth-info {
          text-align: left;
          background: #fefefe;
          color: clr(font-primary);
          width: 50%;
          margin: 0;
          padding: 2rem;
          img {
            margin: 2rem auto 1rem auto;
          }
        }

        &--sign-in,
        &--sign-up.minimal {
          width: 30rem;
        }

        &--sign-up:not(.minimal) {
          width: 48rem;

          .auth-form {
            width: 42%;
            background: clr('gray');
          }

          .auth-info {
            width: calc(100% - 42%);
          }
        }
      }
    }
  }
}

@mixin auth-medium() {
  .auth {
    h3 {
      font-size: 1.25vw;
    }

    .auth-content {
      .auth-content-wrapper {
        &--sign-up:not(.minimal) {
          width: 60rem;
        }
        .auth-info {
          padding: 2.5rem 3rem;
        }
      }
    }
  }

  .invalid-invite .auth-form {
    max-width: 31rem !important;
  }
}

@mixin auth-large() {
  .auth .auth-content .auth-content-wrapper .auth-info {
    img {
      margin: 3rem auto 2rem auto;
    }
  }
}

@import '../abstracts/util';

@mixin alert-base() {
  $close-button-space: 3.5rem;

  .alert {
    position: fixed;
    width: 100%;
    //must be at least 102 for the react-awesome-popovers and #header
    z-index: 9999;
    transition: transform linear 0.5s;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: transform ease-out 0.25s;
    min-height: $close-button-space;

    ul {
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }

    .alert__header {
      padding: 0.75rem $close-button-space 0.75rem 1.5rem;
    }

    .alert-close {
      margin-left: auto;
      cursor: pointer;
      padding: 0.75rem 1.5rem;
      position: absolute;
      right: 0;
      top: 0;
    }

    .alert__details {
      padding: 0.75rem;
      background: #fff;
      border: 2px solid clr('warning');
      overflow-x: auto;
    }

    .alert-actions {
      display: flex;
      align-items: center;
    }

    &.alert-success {
      background: clr('success');
    }

    &.alert-error {
      background: clr('warning');
    }

    &.alert-block {
      max-width: none;
    }

    &.active {
      transform: translateY(0);
    }

    &.no-fixed {
      position: initial;
      transition: none;
      transform: none;
    }
  }

  .inline-alert {
    padding: 1rem;
    background-color: clr('warning');
    margin-bottom: 1.5rem;
    border-radius: config('borderRadius');
  }
}

@mixin alert-small() {
  .alert {
    max-width: 40rem;
    margin-left: -20rem;
    left: 50%;
  }
}

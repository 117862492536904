@import '../abstracts/util';

@mixin graphql-index-base() {
  .graphql-header-action-item {
    display: inline-flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    .rap-target-container {
      padding-left: 0;
    }

    button {
      padding: 0.5rem;
    }
  }
}

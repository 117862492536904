@import '../abstracts/util';

@mixin button-gradient($angle, $start-color, $end-color) {
  background: linear-gradient($angle, $start-color, $end-color);
}

@mixin button-base() {
  button {
    border: 0;
    background: none;
    padding: 0;
    margin: 0;

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  button::-moz-focus-inner {
    border: 0;
  }

  .btn {
    transition: all 0.3s;
    border-radius: config('borderRadius');
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    min-height: 2.25rem;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.75rem;
    letter-spacing: 0.03rem;
    line-height: 0.9rem;
    z-index: 1;
    position: relative;

    .svg-inline--fa,
    img {
      margin-right: 0.5rem;
      font-size: inherit;
      min-width: 0.75rem;
      height: 1rem;
    }

    .svg-inline--fa.disabled {
      color: clr('dark-stroke');
    }

    .btn-label {
      @extend .center;
      white-space: nowrap;

      > span {
        line-height: 1;
      }
    }

    .btn-spinner {
      position: absolute;
      width: 100%;
      text-align: center;
      height: 1rem;
      opacity: 0;
      left: 0;
      top: 50%;
      margin-top: -0.5rem;
      margin-right: 0;
    }

    &.btn-primary {
      color: chooseContrastColor(primary);
      background-color: clr('primary');

      svg path {
        color: chooseContrastColor(primary);
      }
    }

    &.btn-subtle-link {
      //TODO: Make better hover, active, focus effects
      color: clr('link');

      &:hover {
        background: clr('link-hover-bg');
      }
    }

    &.btn-secondary {
      color: clr('font-secondary') !important;
      border: 1px solid clr('soft-stroke');
      background-color: clr('secondary');
    }

    &.btn-success {
      @include button-gradient(90deg, clr('cta-primary'), clr('cta-primary-gradient'));

      color: clr('white');
      svg path {
        color: clr('white');
      }
    }

    &.btn-info {
      color: chooseContrastColor(info);
      background-color: clr('info');
    }

    &.btn-warning {
      color: chooseContrastColor(warning);
      background-color: clr('warning');
    }

    &.btn-danger {
      color: chooseContrastColor(danger);
      background-color: clr('danger');
    }

    &.btn-light {
      color: chooseContrastColor(light);
      background-color: clr('light');
    }

    &.btn-dark {
      color: chooseContrastColor(dark);
      background-color: clr('dark');
    }

    &.btn-muted {
      color: clr('font-secondary');
    }

    &.btn-github {
      background-color: clr('github');
      color: clr('white');
      &:hover {
        background-color: darken(clr('github'), 50%);
      }
    }

    &.btn-netlify {
      background-color: clr('netlify');
      color: clr('white');
      &:hover {
        background-color: darken(clr('netlify'), 5%);
      }
    }

    &.btn-large {
      padding: 0.875rem 1rem;
    }

    &.btn-small {
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      line-height: 1.5;
    }

    &.btn-block {
      display: block;
      width: 100%;
    }

    &.btn-inline {
      display: inline-block;
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &.btn-newEdit {
      margin-right: auto;
    }
    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    &.btn-loading {
      line-height: 0;
      position: relative;

      .btn-label {
        opacity: 0;
      }

      .btn-spinner {
        opacity: 1;
      }
    }
  }

  .button-group {
    display: flex;
    .btn {
      margin-right: 0.25rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .button-group-left {
      margin-right: auto;
    }

    &--right {
      justify-content: flex-end;
    }
  }
}

@import '../abstracts/util';

@mixin popover-base() {
  #rap-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
  }

  .rap-manager,
  .rap-target-container {
    display: inline-block;
    position: relative;
  }

  .rap-left {
    left: -20px !important;
  }
  .rap-bottom {
    top: 15px !important;
  }
  .rap-top {
    top: -15px !important;
  }
  .rap-right {
    left: 20px !important;
  }

  .ar {
    transform: rotate(-180deg);
  }
  .at {
    transform: rotate(90deg);
  }
  .ab {
    transform: rotate(-90deg);
  }
  .rap-target-container {
    cursor: pointer;
    margin: left 0.02em !important;
  }

  .rap-manager {
    width: auto;

    span {
      svg {
        opacity: 0.7;
      }
    }

    .rap-target-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      padding-left: 0.5rem;
    }
  }

  .popover-content {
    background: clr('black');
    color: clr('white');
    border-radius: config('borderRadius');
    padding: 0.875rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.875rem;
    width: auto;
    font-weight: normal;
    text-align: left !important;
    &-large {
      @extend .popover-content;
      width: 19rem;
    }

    p {
      b {
        color: clr('font-primary');
      }

      &:last-child {
        margin: 0;
      }
    }

    a {
      display: inline-block !important;
      vertical-align: baseline !important;
      color: clr('white') !important;
      text-decoration: underline !important;
      font-weight: bold !important;
    }
  }
}

/* light theme */
@import '../main';

$primary: #3f00a5;
$font-primary: #2c3160;
$font-secondary: #492fb1;

.light {
  $theme: (
    colors: (
      'primary': $primary,
      'secondary': #eff0f6,
      'success': #4bffc4,
      'dark-success': #4dd69a,
      'info': #8464b9,
      'warning': #ffd546,
      'danger': #fc615e,
      'light': #f8f9fa,
      'dark': #343a40,
      'white': #fff,
      'black': #000,
      'gray': #f4f5f9,
      'netlify': #02ad9f,
      'github': #282d34,
      'white-soft': rgba(255, 255, 255, 0.95),
      'white-most-transparent': rgba(255, 255, 255, 0.1),
      'font-primary': $font-primary,
      'font-secondary': $font-secondary,
      //TODO: replace this an others below with variables from the palette above
        'font-muted': #7c82ba,
      'font-tooltips': #7c82ba,
      'header-bg': #f7f7fa,
      'header-shadow': 0px 1px 1px rgba(0, 0, 0, 0.15),
      'footer-bg': transparent,
      'sidebar-fg': $font-secondary,
      'sidebar-bg': #fff,
      'sidebar-active-bg': rgba(100, 116, 175, 0.1),
      'sidebar-shadow': 3px 0px rgba(200, 200, 200, 0.4),
      'content-bg': rgba(100, 116, 175, 0.075),
      'breadcrumb-fg': #5261c5,
      'breadcrumb-bg': #eff1f8,
      'breadcrumb-alt': $font-secondary,
      'stroke': #d7d7e8,
      'soft-stroke': #eaeaee,
      'dark-stroke': #84899e,
      'link': #525ebc,
      //#3F4CCF,
        'link-hover-bg': rgba(#525ebc, 0.05),
      'card-shadow': 0px 1px 4px #d7d7e8,
      'card-shadow-hover': 1px 1px 5px #a2a2a2,
      'observation': #767676,
      'json-display-fg': #4a4a4a,
      'json-display-bg': #eeeff3,
      'code-hightlight': #5c6482,
      'code-editor-bg': #282a36,
      'dark-active-tab': #71778f,
      'webshell-bg': #192241,
      'tip-warning': #efa038,
      'query-metric-bg': #404761,
      'soft-bg': #fafbff,
      'primary-with-opacity': #7c82ba4c,
      'header-nav-bg': $font-secondary,
      'auth-bg': #f3f4ff,
      'tab-color': $font-secondary,
      'toggle-content-border': #d7d7e9,
      'cta-primary': #3f00a5,
      'cta-primary-gradient': #813eef
    ),
    config: (
      'contrastPoint': 64,
      'headerHeight': 3.125rem,
      'subheaderHeight': 3rem,
      'touch-min': 2.25rem,
      'borderRadius': 0.25rem,
      //~36px
        'touch': 2.75rem,
      //44px
        //TODO: change this to rem. it is introducing a bug right now
        'sidebarWidth': 164px //to match logo width
    ),
    application: (
      font-family: #{acumin-pro,
      Helvetica,
      sans-serif},
      font-bold: 600
    )
  );
  @include main($theme);
}

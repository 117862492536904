@import '../abstracts/util';

@mixin form-base() {
  $label-margin-bottom: 0.3rem;

  label,
  .label {
    opacity: 0.8;
    display: block;
    padding-bottom: $label-margin-bottom;
    box-sizing: border-box;

    &.required {
      &:after {
        content: '*';
        color: clr('danger');
        font-size: 1rem;
        position: absolute;
        margin-top: -0.15rem;
        margin-left: 0.15rem;
      }
    }
    &.optional {
      &:after {
        content: '(optional)';
        color: clr('font-secondary');
        opacity: 0.5;
        margin-left: 0.25rem;
      }
    }
  }

  .form-label--tooltip {
    display: flex;
    align-items: center;

    .rap-target-container {
      span {
        height: config('touch-min');
        width: config('touch-min');
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -0.5rem;
      }
    }
  }

  input,
  .form-text,
  select {
    border: 1px solid clr('stroke');
    border-radius: config('borderRadius');
    background: clr('white');
    padding: 0.6rem;
    outline: none;
    box-shadow: none;
    font-size: 1rem;

    &:focus {
      border-color: clr('primary');
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 0.25rem);
    background-position-y: 50%;
  }

  .form-group {
    input,
    .form-text,
    select {
      display: block;
      width: 100%;
      padding: 0.6rem;
    }

    ul {
      padding-left: 0;
      list-style-position: inside;
      margin: 0;
    }

    p svg {
      margin-right: 0.75rem;
    }
  }

  select {
    height: config('touch');
  }

  .form-text {
    margin: 0;
    padding: 0;
  }

  .form-label-row {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-actions {
    display: flex;
    margin-top: 2rem;

    .btn {
      width: 100%;
      margin-right: 0.5rem;
      max-width: 50%;

      &:last-child {
        margin-right: 0;
      }
    }

    &--document-form {
      min-width: 100%;

      .btn {
        flex: 0 1 120px;
        margin-bottom: 0.5rem;

        &:last-child {
          flex: 0 1 160px;
          margin-left: auto;
        }
      }
    }
  }

  .form-tags {
    border: 1px solid clr('stroke');
    padding: 1rem * 0.6;
    width: 100%;

    input {
      margin: 0;
      border: 0;
      display: initial;
      width: initial;
      padding: 0;
    }

    &.form-tags-read-only {
      padding: 0;
      border: 0;
    }
  }

  .form-checkbox {
    display: flex;

    label {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: clr('font-primary');
      margin: 0;
      cursor: pointer;
      &.switch {
        margin-right: 8px;
      }
    }

    input {
      display: initial;
      width: initial;
      transform: scale(1.5);
      margin: 0 0.75rem 0.15rem 0.25rem;

      &:disabled {
        opacity: 0.5;
      }
    }

    svg {
      margin-right: 0.5rem;
      color: clr('primary');
    }

    .rap-manager {
      .rap-target-container {
        span {
          top: -1rem;
          padding-right: 0;
          display: flex;
        }
      }
    }
  }

  .input-group {
    display: flex;

    input,
    .input--code,
    select {
      flex: auto;
      width: auto;
      min-width: 12rem;
    }

    .input--search {
      font-size: 0.875rem;
    }

    button {
      padding-top: 0.65rem;
      padding-bottom: 0.65rem;
      line-height: initial;
      border: 1px solid clr('primary');
      min-width: 6.5rem;
    }

    &.input-group--vertical {
      flex-flow: column;
      input,
      .code-editor,
      select {
        width: 100%;
        &:first-child {
          border-bottom: 0;
        }
      }
      input,
      .input--code {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        //to hide bottom radius on select boxes
        margin-top: -(config('borderRadius'));
      }
      select {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        //to hide bottom radius on select boxes
        height: (config('touch')) + (config('borderRadius'));
      }
    }

    &:focus-within {
      input,
      select {
        border-color: clr('primary');
      }
    }
  }

  form.form--inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  form.form--search {
    input {
      width: 100%;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .btn {
      min-width: auto;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  form.form--filter.form--inline {
    align-items: flex-start;
    flex-flow: wrap;

    label {
      font-size: 1rem;
      color: clr('font-primary');
    }

    .form-group {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn {
      // Necessary to maintain the same height of the fields.
      padding: 0.788rem;
      // Align to the input top
      margin-top: 1.9375rem;

      svg {
        margin: 0;
        font-size: 1rem;
      }
    }

    .input--code {
      // Align input--code on Index Search Form
      padding: calc((41.19px - 14px) / 2) 0.5rem;
      background: clr('code-editor-bg');
      width: 100%;
    }
  }

  .form-field__line {
    margin-bottom: 0.25rem;
    align-items: center;
    flex-direction: row !important;

    .form-field__button {
      padding: 0;
      text-align: center;
      width: 1rem;

      svg {
        color: clr('primary');
        font-size: 1rem;
      }

      &.form-field__button--remove {
        opacity: 0.5;
      }
    }
  }

  .select {
    &.select--with-button {
      display: flex;

      select {
        flex: 1;
      }
    }
  }

  .form-instruction {
    font-size: 0.85em;
  }

  .form-input {
    margin-bottom: 4px;
    padding: 8px 12px !important;
  }

  .form-input-label {
    font-size: 0.95em;
  }

  .form-validation-label {
    display: block;
    font-size: 0.85em;

    p {
      margin: 0;
    }
  }

  .valid {
    color: clr('success');

    &--border {
      border: 1px solid clr('success');
    }
  }

  .invalid {
    color: clr('danger');

    &--border {
      border: 1px solid clr('danger');
    }
  }

  // react-phone-number-input
  .PhoneInput {
    .PhoneInputCountry {
      background: clr('white');
      border: 1px solid clr('stroke');
      border-right: 0;
      padding: 0 1rem;
      border-radius: config('borderRadius');
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-right: 0;
    }

    .PhoneInputCountryIcon--border {
      box-shadow: none;
      background-color: clr('secondary');
    }

    .PhoneInputCountrySelect {
      position: absolute;
    }

    .PhoneInputInput {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

@mixin form-small() {
  .form-label--tooltip {
    margin: 0;

    .rap-target-container {
      span {
        display: flex;
        align-items: normal;
        justify-content: center;
        margin: 0;
        width: auto;
        height: auto;
      }
    }
  }
}

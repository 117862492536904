@import '../abstracts/util';

@mixin framework-base() {
  .app {
    background: app('background');
  }

  // horizontal links in a <ul>
  ul.links {
    @extend .smaller;
    align-self: center;
    padding-left: 0;

    li {
      display: inline-block;
      border-right: 1px solid silver;
      padding-right: 1rem;
      margin-right: 1rem;

      &:last-child {
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    color: clr('stroke');
    background-color: clr('stroke');
    margin: 0;
  }

  pre {
    margin: 0;
    padding: 0;
  }

  code {
    font-family: 'Source Code Pro', monospace !important;
  }

  *[draggable='true'] {
    cursor: grab;
  }
}

@import '../abstracts/util';

@mixin scroller-base() {
  .scroller {
    overflow-x: auto;
    padding: 0.25rem;
    max-width: calc(100vw - 3rem);

    .scroller-wrapper {
      display: flex;

      > * {
        margin-right: 1rem;
      }

      > *:last-child {
        margin-right: 0;
      }

      &::after {
        content: '.';
        opacity: 0;
      }
    }
  }
}

@mixin scroller-small() {
  .scroller {
    max-width: calc(100vw - 3rem - 3.125rem);
  }
}

// Font Weight Variables
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;

@mixin font-base() {
  //TODO: add this body color back into back into the body, *, *:before, *:after selector below it
  //right now, it is just slowing down design as i'm messing with the typography and CTA colors
  body {
    color: clr(font-primary);
  }

  body,
  *,
  *:before,
  *:after {
    font-family: app(font-family);
  }

  a {
    text-decoration: none;
    color: clr('link');
  }

  a.current-color {
    color: inherit;
  }

  a[href=''],
  a:not([href]) {
    cursor: initial;
    color: clr('font-muted');
  }

  th {
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 400;
    margin: 0.5rem 0;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.5rem;
  }

  h3,
  .h3 {
    font-size: 1.25rem;
  }

  h4,
  .h4 {
    font-size: 1.1rem;
  }

  .smaller {
    font-size: 0.75rem;
  }

  .text-normal {
    font-size: 1rem;
  }

  .font-size-14 {
    font-size: 0.875rem;
  }

  p {
    margin-top: 0;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-left {
    text-align: left !important;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-2xl {
    font-size: 48px;
  }

  .text-xl {
    font-size: 36px;
  }

  .text-28 {
    font-size: 28px;
  }

  .text-lg {
    font-size: 20px;
  }

  .text-16 {
    font-size: 16px;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .lowercase {
    text-transform: lowercase;
  }

  .italic {
    font-style: italic;
  }

  .white {
    color: white;
  }

  .muted {
    color: clr('font-muted');
  }

  .ghost {
    opacity: 0.5;
    color: clr('dark-stroke');
  }

  .primary {
    color: clr('font-primary');
  }

  .secondary {
    color: clr('font-secondary');
  }

  .secondary-neutral {
    color: clr('dark-stroke');
  }

  .success {
    color: clr('success');
  }

  .danger {
    color: clr('danger');
  }

  .breakWord {
    word-break: break-word;
  }

  .line-height-tight {
    line-height: 1.25;
  }

  .line-height-150 {
    line-height: 150% !important;
  }

  b,
  strong,
  .bold {
    font-weight: app(font-bold);
  }
}

@import '../abstracts/util';

@mixin modal-base() {
  $modal-header-bg: clr('header-bg');
  $modal-header-color: clr('observation');

  .modalOverlay,
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(clr('black'), 0.25);
    z-index: 999;
    overflow-y: auto;

    &.modal-hide {
      display: none;
    }
  }

  .modal {
    margin: 1.75rem auto;
    width: 100%;
    max-width: 30rem;
    background-color: clr('white');
    color: clr('font-primary');
    border-radius: config('borderRadius');

    &.smaller {
      max-width: 350px;
    }

    .modalMain {
      flex-direction: column;
      overflow: auto;
      padding: 1rem 2rem;
    }

    .modalActions {
      @extend .center;
      width: 100%;
      padding-top: 1rem;

      .btn {
        margin: 0 0.5rem;
      }
    }

    .modal__close {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.75;
      z-index: 1;

      button {
        width: 2.75rem;
        height: 2.75rem;
      }

      svg {
        font-size: 1rem;
        margin: 0;
      }
    }

    .modal__body {
      padding: 1rem 1.5rem 1.5rem;
    }

    .modal__header {
      border-top-left-radius: config('borderRadius');
      border-top-right-radius: config('borderRadius');
      background-color: $modal-header-bg;
      color: $modal-header-color;
      display: flex;
      align-items: center;

      .modal__title {
        flex: 1;
        padding: 1rem 1.5rem;
      }

      .modal__close {
        position: initial;

        button {
          width: auto;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
    }

    &--upgrade {
      .modal {
        width: 24rem;
        height: 24rem;
        border-radius: config('borderRadius');
        background-image: url('../../images/modal-upgrade-bg.png');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 120%;
      }
    }
  }
}

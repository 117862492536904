@import '../abstracts/util';

@mixin section-base() {
  .section {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
    border-top: 1px solid clr('stroke');

    &:first-child {
      border-top: none;

      .section__header {
        border-top: none;
      }
    }

    .section__body {
      padding-top: 0.5rem;
    }

    .section__title {
      font-size: 1.25rem;
      margin-right: 1rem;
      white-space: nowrap;
    }

    .section__labels {
      display: flex;
      align-items: flex-end;
      margin-top: 0.5rem;

      .label {
        padding: 0 0.5rem;
        font-size: 0.75rem;
        text-align: center;
        line-height: 1.2;
      }

      &.section__labels--right {
        justify-content: flex-end;
      }
    }

    &--no-border {
      border-top: 0;
    }
  }
}

@mixin section-small() {
  .section {
    .section__header {
      display: flex;

      .section__control {
        display: flex;
        align-items: center;
        width: 50%;
        padding-right: 1rem;

        .section__title {
          min-width: 8rem;

          &.section__tile--large {
            min-width: auto;
          }
        }

        .select {
          width: 100%;
          max-width: 25rem;
        }
      }

      .section__labels {
        margin-left: auto;
        margin-top: 0;
        padding: 0;
        width: 50%;

        .label {
          font-size: 0.875rem;
        }
      }
    }
  }
}

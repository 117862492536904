@import '../abstracts/util';

@mixin list-base() {
  .list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.list-block {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      li {
        display: block;
        padding: 1rem 0;
        border-bottom: solid clr('stroke') 1px;

        > a {
          text-decoration: none;
          display: flex;
          color: clr('link');

          * {
            color: inherit;
          }
        }

        .list-icon {
          font-size: 1.5rem;
          margin-right: 0.875rem;
          width: 1.75rem;
          text-align: center;
        }

        .list-content {
          .list-title {
            font-weight: bold;
            font-size: 0.875rem;
          }

          .list-subtitle {
            font-size: 0.75rem;
          }
        }
      }
    }

    &.list-topics {
      li {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.list-group {
      li {
        display: block;
        padding: 0.75rem 0;
        border-bottom: 1px solid clr('stroke');

        .row {
          flex-direction: row;
        }

        .rap-manager {
          margin: 0;
        }

        &.label {
          padding-bottom: 0.5rem;
        }
      }
    }

    &--stack {
      border: 1px solid clr('stroke');

      li:not(:last-child) {
        border-bottom: 1px solid clr('stroke');
      }

      .list__item {
        width: 100%;
        font-size: inherit;
        color: inherit;
        text-align: left;
        padding: 0.75rem 1rem;

        &--link {
          font-weight: 600;
          color: clr('link');

          &:hover {
            background: clr('link-hover-bg');
          }
        }
      }
    }

    &--ordered {
      list-style-type: decimal;
      padding-left: 0.875rem;

      li {
        margin-bottom: 1rem;
      }
    }
  }
}

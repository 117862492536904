@import '../abstracts/util';

@mixin billing-base() {
  /**
    * Current plan
    */
  .billing__current-plan {
    width: 100%;
    // 2 * container--xsmall + col padding
    max-width: calc(#{$base-width * 0.45 * 2} + 1.5rem);
  }

  .current-plan {
    background: clr('white');
    border: 1px solid clr('stroke');
    border-radius: config('borderRadius');
    padding: 0.75rem 1rem;
  }

  .current-plan__billing-source {
    color: clr('font-secondary');
    margin-left: auto;
  }

  /**
   * Regular plan component
   */
  .plan {
    border-radius: config('borderRadius');
    border: 1px solid clr('stroke');
    background-color: clr('soft-bg');
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover,
    &.selected {
      border-color: clr('primary');
    }
  }

  .plan__header {
    text-align: center;
    padding: 1.75rem 0 calc(1.75rem + 0.75rem);
    line-height: 1.2;
    border-bottom: 1px solid clr('stroke');
    position: relative;
    background-color: clr('white');
    border-top-left-radius: config('borderRadius');
    border-top-right-radius: config('borderRadius');
  }

  .plan__selected-mark {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-bottom: solid 20px transparent;
    border-right: solid 20px transparent;
    border-left: solid 20px clr('primary');
    border-top: solid 20px clr('primary');
    border-top-left-radius: config('borderRadius');
    color: clr('white');
  }

  .plan__selected-mark__icon {
    position: absolute;
    font-size: 0.75rem;
    top: -20px;
    left: -20px;
    margin: 0.35rem;
  }

  .plan__name {
    font-size: 1.25rem;
    color: clr('font-secondary');
    display: block;
  }

  .plan__price {
    font-size: 1.5rem;
    font-weight: $font-bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plan__price__value {
    font-size: 1.75rem;
    margin: 0 0.15rem;
    line-height: 1;
  }

  .plan__price__symbol,
  .plan__price__period {
    font-size: 0.875rem;
    font-weight: $font-regular;
    color: clr('font-secondary');
  }

  .plan__price__symbol {
    // Align $ on top of the number
    margin-top: -0.5rem;
  }

  .plan__button {
    $button-width: 9rem;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: clr('font-secondary');
    border: 1px solid clr('font-secondary');
    font-weight: $font-medium;
    border-radius: config('borderRadius');
    width: $button-width;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: $button-width * 0.5 * -1;
    margin-bottom: -1.5rem;
    background-color: clr('white');
    z-index: 1;
    height: 3rem;
    padding: 0 2rem;
    line-height: 1.4;

    &--current,
    &--downgrade {
      background-color: clr('soft-bg');
      border: 1px solid clr('stroke');
      padding: 0 0.5rem;
    }
  }

  .selected .plan__button {
    background-color: clr('primary');
    color: clr('white');
  }

  .plan__details {
    overflow: hidden;
    padding: calc(1.5rem + 0.75rem) 1rem 1.5rem;
  }

  .plan__offers {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.875rem;
  }

  .plan__offer {
    display: flex;
    margin-bottom: 0.5rem;

    &:last-child {
      margin: 0;
    }
  }

  .plan__offer__check-icon {
    font-size: 80%;
    margin-right: 0.5rem;
    color: clr('dark-success');
  }

  .plan__offer__text {
    margin: 0;
  }

  /**
   * Contact panel
   */

  .billing__usage-contact__panel {
    border: 1px solid clr('stroke');
    border-radius: config('borderRadius');
    background-color: clr('soft-bg');
    text-align: center;

    p {
      margin: 0;
    }
  }

  .billing__usage-contact__panel__header {
    background-color: clr('white');
    font-size: 1.25rem;
    color: clr('font-secondary');
    padding: 0.5rem;
    border-bottom: 1px solid clr('stroke');
  }

  .billing__usage-contact__panel__body {
    padding: 1rem;
  }

  /**
   * Summary
   */
  .summary {
    border-radius: config('borderRadius');
    background-color: clr('white');
    border: 1px solid clr('stroke');
  }

  .summary__header {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid clr('stroke');
  }

  .summary__title {
    margin: 0;
    font-size: 1rem;
    font-weight: $font-bold;
  }

  .summary__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .summary__item {
    font-size: 0.875rem;
    color: clr('font-secondary');
    display: flex;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid clr('stroke');
  }

  .summary__item__value {
    margin-left: auto;
  }

  .summary__total {
    display: flex;
    font-size: 14px;
    font-weight: $font-bold;
    padding: 0.75rem 1rem;
  }

  .summary__total__value {
    margin-left: auto;
  }

  /**
   * Payment loading
   */
  .payment-loading {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    color: clr('font-secondary');
  }

  .payment-loading__spinner {
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.25rem;
  }

  /**
   * Payment method
   */
  .empty-payment-method {
    display: block;
    width: 100%;
    text-align: left;
    font-size: inherit;
    background-color: clr('white');
    padding: 1rem;
    border: 1px solid clr('stroke');
    border-radius: config('borderRadius');

    &:hover {
      border: 1px solid clr('primary');
    }
  }

  .empty-payment-method__icon {
    color: clr('font-secondary');
    margin-right: 1rem;
  }

  .payment-method {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    background-color: clr('white');
    padding: 0.75rem 1rem;
    border: 1px solid clr('stroke');
    border-radius: config('borderRadius');
    cursor: pointer;
    margin-bottom: 0.15rem;

    &:hover,
    &.selected {
      border-color: clr('primary');
    }
  }

  .payment-method__info {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
  }

  .payment-method__radio {
    margin: 0;
  }

  .payment-method__icon,
  .payment-method__radio,
  .payment-method__number {
    margin-right: 1rem;
  }

  .payment-method__actions {
    color: clr('font-secondary');
    margin-left: auto;

    button {
      color: inherit;

      &:hover {
        color: clr('font-primary');
      }
    }
  }

  .add-payment-method {
    font-weight: $font-bold;
    color: clr('font-secondary');
    margin-top: 0.5rem;

    &:hover {
      color: clr('font-primary');
    }
  }

  /**
   * Billing checkbox
   */

  .billing {
    .form-checkbox {
      label {
        align-items: baseline;
      }

      input {
        width: auto;
        transform: none;
      }
    }
  }

  /**
   * Billing actions
   */
  .billing__actions {
    margin-top: 1.5rem;
    border-top: 1px solid clr('stroke');
    padding-top: 1.5rem;
    display: flex;

    button {
      width: 10rem;

      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }

  /**
   * Upgrade team banner
   */
  .upgrade-team-banner {
    padding: 1.75rem;
    background-color: clr('white');
    border-radius: config('borderRadius');
    border: 1px solid clr('stroke');
  }

  .upgrade-team-banner__title {
    margin-top: 0;
    line-height: 1.2;
    margin-bottom: 0.75rem;
  }

  .upgrade-team-banner__text {
    color: clr('font-secondary');
  }

  .upgrade-team-banner__col-left {
    max-width: 38rem;
  }

  .upgrade-team-banner__col-right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .upgrade-team-banner__price {
    color: clr('font-secondary');
    text-align: center;
    margin-right: 2rem;
  }

  .upgrade-team-banner__price__value {
    display: flex;
    align-items: flex-start;
    line-height: 1;
  }

  .upgrade-team-banner__price__currency {
    margin-top: 0.75rem;
    margin-right: 0.15rem;
  }

  .upgrade-team-banner__price__number {
    font-size: 3.5rem;
  }

  .upgrade-modal .modal {
    max-width: 32rem;
    height: auto;
    padding: 1.25rem;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100%;
  }

  .upgrade-modal--rocket .modal {
    background-image: url('../../images/rocket-and-clouds.svg');
    height: 38rem;
  }

  .upgrade-modal__content {
    display: flex;
  }

  .upgrade-modal__title {
    font-size: 1.5rem;
    color: clr('font-secondary');
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .upgrade-modal__pricing {
    text-align: center;
    flex: 1;
    padding: 0 2rem;
  }

  .upgrade-modal__offers {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      margin-bottom: 0.875rem;
      display: flex;
      align-items: flex-start;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        border-left: 3px solid clr('primary');
        margin-bottom: 0.5rem;
        line-height: 1;
        padding-left: 0.5rem;
      }
    }
  }

  .upgrade-modal__offer-icon {
    color: clr('font-secondary');
    margin-right: 0.75rem;
    margin-top: 0.35rem;
    width: 2rem;
    font-size: 1.25rem;
  }

  .upgrade-modal__price {
    color: clr('font-secondary');
    line-height: 1;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upgrade-modal__currency {
    font-size: 1.5rem;
    line-height: 1;
    vertical-align: top;
    position: relative;
    top: 0.5rem;
    margin-right: 0.25rem;
  }

  .upgrade-modal__value {
    font-size: 2.75rem;
    font-weight: 500;
  }

  .upgrade-modal__recurrency {
    display: block;
    margin-top: 0.25rem;
  }

  /**
   * Billing structure
   */
  .billing {
    padding: 1rem 0;
  }

  .billing__section {
    margin-bottom: 1.25rem;
  }

  .billing__section__title {
    font-size: 1rem;
    margin: 0;
    @extend .label;
  }

  .billing__usage-plans {
    margin-bottom: 1rem;

    .plan {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .billing__fine-print {
    color: clr('font-primary');
    font-size: 0.7rem;
    padding-left: 12px;
  }
}

@mixin billing-small() {
  /**
   * Billing structure
   */
  .billing {
    padding: 0;
    display: flex;
  }

  .billing__left-col {
    margin-right: 1.5rem;
  }

  .billing__usage-plans {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    gap: 0.5rem;

    .plan {
      margin: 0;
    }
  }

  .upgrade-team-banner {
    display: flex;
  }

  .upgrade-team-banner__title {
    line-height: 1;
  }

  .upgrade-team-banner__text {
    margin-bottom: 0;
  }

  .current-plan {
    display: flex;
    align-items: center;
  }
}

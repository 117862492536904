@import '../abstracts/util';

@mixin display-base() {
  .align-items-center {
    align-items: center;
  }

  .display-none {
    display: none !important;
  }

  .display-flex {
    display: flex;
  }

  .display-block {
    display: block;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .flex-direction-column-reverse {
    flex-direction: column-reverse;
  }

  .justify-content-end {
    justify-content: end;
  }

  .justify-content-space-evenly {
    justify-content: space-evenly;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .justify-content-space-around {
    justify-content: space-around;
  }

  .align-items-center {
    align-items: center;
  }

  .opacity-100 {
    opacity: 1 !important;
  }
}

@mixin display-xsmall() {
  .display-xsmall-block {
    display: block !important;
  }
}

@mixin display-small() {
  .display-small-block {
    display: block !important;
  }

  .display-small-inline-block {
    display: inline-block !important;
  }

  .display-small-flex {
    display: flex !important;
  }

  .display-small-none {
    display: none !important;
  }
}

@import '../abstracts/util';

@mixin flexGrid-base() {
  $gutter: 1rem;

  .grid {
    flex-direction: column;
    width: 100%;

    .row {
      display: flex;
      margin-left: -1 * $gutter * 0.5;
      margin-right: -1 * $gutter * 0.5;
      flex-wrap: wrap;

      .col {
        width: 100%;
        padding-left: $gutter * 0.5;
        padding-right: $gutter * 0.5;

        &.col--auto {
          width: auto;
        }
      }

      &.row--no-wrap {
        flex-wrap: nowrap;
      }

      &.items-end {
        align-items: flex-end;
      }

      &--space-between {
        justify-content: space-between;
      }
    }

    &.grid-row {
      display: flex;
    }

    &.grid-gutter > div {
      padding: 0 $gutter * 0.5;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &.grid-center {
      align-items: center;
    }
  }

  .flex-auto {
    flex: auto;
  }
}

@mixin flexGrid-small() {
  $gutter: 1rem;

  .grid {
    .row {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &.grid-gutter-sm {
    .row {
      .col {
        padding: 0 $gutter * 0.5;
      }
    }
  }
}

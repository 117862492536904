@import '../abstracts/util';

@mixin observation-base() {
  .observation {
    font-size: 0.75rem;
    color: clr('observation');
    font-style: italic;
    margin: 1rem 0 0;

    &.no-margin {
      margin: 0;
    }
  }
}

@import '../abstracts/util';

@mixin databases-panel-base() {
  .databases-panel {
    @extend .card;
  }

  .databases-panel__header {
    @extend .card-header;
  }

  .databases-panel__body {
    max-height: 30rem;
    overflow-y: auto;
  }

  .databases-panel__chart {
    padding: 1rem;
  }

  .databases-panel__table__account-row {
    box-shadow: none;

    &:hover {
      background: clr('white') !important;
      cursor: default;
    }

    td {
      border-left-color: clr('stroke') !important;
      color: clr('dark-stroke');
      font-weight: 500;
      padding-left: 4px;

      .rap-target-container {
        padding-bottom: 0;
        padding-top: 0;

        span {
          padding-left: 0;
        }
      }
    }
  }
}

@mixin databases-panel-small() {
  .databases-panel__body {
    display: flex;
    height: 20rem;
    overflow-y: initial;
  }

  .databases-panel__chart {
    width: 30%;
  }

  .databases-panel__table {
    width: 70%;
    padding-top: 0;

    th {
      position: sticky !important;
      top: 0;
      background: #fff;
      z-index: 1;
    }
  }
}

@mixin databases-panel-medium() {
  .databases-panel__chart {
    width: 25%;
  }

  .databases-panel__table {
    width: 75%;
  }
}

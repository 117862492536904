@import '../abstracts/util';

@mixin table-base() {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    a {
      text-decoration: none;
    }
  }

  th.selected,
  span.selected {
    font-weight: app('font-bold');
    color: clr('font-primary') !important;
  }

  thead {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .tr-link {
    box-shadow: clr(card-shadow);
    border: 1px solid clr(soft-stroke);
    padding: 0.5rem;
    cursor: pointer;
    &:hover {
      background: clr('link-hover-bg') !important;
    }
  }

  .tr-link,
  tbody tr {
    background: clr('white');
    margin-bottom: 0.25rem;
    display: block;
  }

  .dbNameColumn {
    flex-shrink: 0;
    font-weight: app(font-bold);
    color: clr('link');
    span:nth-child(2) {
      text-align: right;
    }

    .withTooltip {
      display: flex;
      align-items: center;
    }
  }

  td {
    vertical-align: middle;
    padding: 0.1rem 0.5rem 0.1rem 50%;
    border-bottom: 1px solid clr('soft-stroke');
    span {
      &:nth-child(2) {
        display: block;
      }
    }
  }

  .no-table-labels {
    tr a {
      min-height: config(headerHeight);
      display: flex;
      align-items: center;
      word-break: break-all;
      line-height: 1.2;
      padding: 0.25rem 0.25rem 0.5rem 0;
    }

    td {
      padding: 0 1rem;
    }
  }

  .mobile-only {
    width: 45%;
    white-space: nowrap;
  }

  table.table-fixed {
    table-layout: fixed;
  }

  .table--no-border {
    a {
      border-bottom: 0 !important;
      border-top: 0 !important;
    }
  }

  .log-source-label {
    padding: 2px;
    border-radius: 4px;
    border: 1px solid #492fb1;
    color: #492fb1;
    margin-left: 8px;
    font-size: 12px;
  }
}

@mixin table-medium() {
  table {
    display: table;
    width: 100%;

    .mobile-only {
      display: none;
    }

    .tr-link,
    tr {
      display: table-row;
    }
    .tr-link {
      box-shadow: none;
    }
    td {
      padding: 0.15rem 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-bottom: 1px solid clr('soft-stroke');
        .btn {
          margin-left: auto;
        }
      }

      &.dbNameColumn {
        span {
          padding-left: 1rem;

          &:nth-child(2) {
            text-align: left;
          }
        }
      }
    }

    &.table-card {
      border-spacing: 0;

      thead {
        display: table-header-group;
        position: relative;
        top: 0;
        left: 0;

        th {
          font-size: 0.85rem;
          color: clr('font-muted');
          padding: 0 0.5rem 0 0;
          line-height: 1rem;
          position: relative;
          padding: 0.25rem 0;
          border-bottom: 1px solid clr('soft-stroke');
        }
      }

      th,
      td {
        display: table-cell;
        text-align: center;
      }

      th:first-child {
        text-align: left;
      }

      tr {
        a,
        .table-link {
          display: flex;
          vertical-align: middle;
          border-left: 0.25rem solid transparent;
          padding-left: calc(1rem - 0.25rem);
        }

        th a {
          border-left: none;
          padding-left: 0;
        }
      }

      tbody {
        display: table-row-group;

        tr {
          height: config(headerHeight);

          td {
            border-left: 0;

            .dbUsageColumn {
              &.text-right {
                padding-right: 0.5rem;
              }
            }

            &:first-child {
              text-align: left;
            }
          }

          &.selected {
            a {
              border-left: 0.25rem solid clr('primary');
              background-color: clr('link-hover-bg');
            }
            td {
              border-left: 0;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.tr-link {
            td {
              &:first-child {
                border-left: 0.25rem solid transparent;
              }
              &.dbNameColumn {
                padding-left: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

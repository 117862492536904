@import '../abstracts/util';

@mixin card-base() {
  .card {
    display: block;
    background: clr('white');
    border-radius: config('borderRadius');
    border: 1px solid clr(soft-stroke);
    margin-bottom: 1rem;

    .card-header {
      display: flex;
      align-items: center;
      background: clr(header-bg);
      border-top-left-radius: config('borderRadius');
      border-top-right-radius: config('borderRadius');
      min-height: config(headerHeight);
      padding: 0 1rem;
      flex-wrap: wrap;
      justify-content: space-between;

      h3 {
        line-height: 0;
        margin-right: 0.5rem;
      }

      .btn-subtle-link {
        margin-right: auto;
        margin-left: 1rem;
      }

      .rap-manager {
        width: auto;

        .rap-target-container {
          width: auto;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      margin-right: auto;

      .btn {
        padding-left: 0;
        padding-right: 0;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .card-body {
      min-height: config(headerHeight);
      padding: 0.5rem 1rem 0.5rem 1rem;
      align-items: center;
      display: flex;
      justify-content: center;

      > .row {
        width: 100%;
        align-items: center;
        margin: 0;
        flex-direction: row;
        justify-content: space-between;

        .col {
          width: auto;
        }

        .col:first-child {
          padding-left: 0;
        }

        .col:last-child {
          padding-right: 0;
        }
      }

      .card-document-ref {
        font-variant-numeric: tabular-nums;
      }
    }

    .card-footer {
      padding-top: 1rem;
    }

    .card-collapse {
      height: 0;
      padding: 0 1rem;
      overflow: hidden;

      > .card-collapse-container {
        visibility: hidden;
      }

      &.open {
        padding: 0 1rem 1rem;
        height: auto;
        > .card-collapse-container {
          visibility: visible;
        }
      }
    }

    &.card-flex {
      display: flex;
      flex-direction: column;
      &.stretch {
        height: calc(100% - 1rem);
      }
      .card-body {
        flex: 1;
      }
    }

    &.card-feature {
      .toggle__label {
        font-weight: 500;
        padding: 0.5rem 1rem;
      }
      .toggle__content {
        padding: 1rem;
        border-left: none;
        border-top: 1px solid clr(soft-stroke);
        margin: 0;
      }
    }

    &.card-role {
      .card-role__header {
        display: flex;
        align-items: center;

        &.card-role__header--inline {
          width: 100%;
          padding-right: 0;

          .card-role__name {
            width: 100%;
          }
        }
      }

      .card-role__name {
        padding: 0.75rem 1rem 0.5rem;

        svg {
          width: 1rem;
          font-size: 0.75rem;
          margin-right: 0.5rem;
        }
      }

      .card-role__remove {
        .btn {
          margin: auto;
        }
      }

      .card-role__options {
        padding: 0;
        display: flex;
        justify-content: flex-end;
      }

      .card-role__option {
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .clickable {
          width: 1.5em;
          height: 1.5em;
          border-width: 1px;
          border-style: solid;
          border-radius: 1px;
          padding: 4px;
        }

        .dirty {
          color: clr('white');
          background-color: clr('primary');
        }

        .success.dirty {
          background-color: clr('success');
        }

        .danger.dirty {
          background-color: clr('danger');
        }
      }

      .card-role__code {
        background: clr('code-editor-bg');
        flex-basis: 100%;

        .card-role__code__tabs {
          display: flex;
          border-bottom: 1px solid clr('dark-stroke');
          justify-content: flex-end;
        }

        .card-role__code__tab {
          text-align: center;
          color: clr('secondary');
          padding: 0.5rem;
          border-right: 1px solid clr('dark-stroke');
          border-bottom: 3px solid transparent;
          border-top: 3px solid transparent;

          &:last-child {
            border-right: none;
          }

          &.is-active {
            background: clr('dark-active-tab');
            border-bottom: 3px solid clr('dark-stroke');
          }
        }

        .card-role__code__editor {
          padding-top: 1rem;
          position: relative;

          .card-role__code__btn-clear {
            color: clr('secondary');
            position: absolute;
            right: 0.75rem;
            bottom: 0.75rem;
          }

          &--empty {
            height: 15rem;
            @extend .center;
            color: clr('dark-stroke');
          }
        }
      }
    }

    .scrollList {
      max-height: config(headerHeight) * 6.25;
      overflow-y: auto;
    }
  }

  .card-list {
    .card-list-header {
      display: flex;
      padding-left: 1rem;
      padding-right: 1rem;

      .label {
        &:first-child {
          position: relative;
          left: -1rem;
        }
      }

      .row {
        flex-direction: row;
        align-items: flex-end;
        margin-left: 0;
        margin-right: 0;

        .label {
          &:first-child {
            left: -1.5rem;
          }
        }
      }
    }

    .card-list-body {
      .card-body {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 0 0.25rem;
      }

      .data-row,
      .action-row {
        display: flex;
        align-items: center;
        max-width: 100%;
        width: 100%;
      }

      .action-row {
        flex-wrap: wrap;
        padding-left: 0.25rem;

        .svg-inline--fa {
          margin: 0;
        }
      }

      .card {
        margin-bottom: 0.25rem;
        &:hover {
          // TODO:refactor accordion html so that this effect is on the link/action itself and not the row
          //box-shadow: clr("card-shadow-hover");
          // cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .documentRef {
    white-space: nowrap;
    margin-left: 0.5rem;
    display: block;
  }
  .documentPreview {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .documentEditToggle {
    margin-right: 0.5rem;
    position: relative;
    text-align: center;

    .fa-chevron-down {
      width: 0.6rem;
      margin: 0 0.25rem;
    }
    .fa-chevron-right {
      width: 0.4rem;
      margin: 0 0.25rem;
    }
  }
}

@mixin card-xsmall() {
  .card {
    .card-header {
      padding: 0 1rem;

      .actions {
        .btn {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}

@mixin card-small() {
  .card {
    .card-header {
      > .dropdown:first-child {
        .dropdown-value {
          font-weight: app(font-bold);
        }
      }
    }

    &.card-role {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .card-role__header {
        width: 50%;
        padding-right: 1rem;
      }

      .card-role__name {
        padding: 1rem;
      }

      .card-role__options {
        width: 50%;
      }

      .card-role__code {
        .card-role__code__tabs {
          padding-left: 50%;
        }

        .card-role__code__tab {
          &:first-child {
            border-left: 1px solid clr('dark-stroke');
          }

          &:last-child {
            border-right: 1px solid clr('dark-stroke');
          }
        }
      }
    }

    .scrollList {
      height: config(headerHeight) * 6.25;
    }
  }

  .card-list {
    .card {
      padding: 0;
    }

    .card-list-body {
      .data-row {
        flex: 1 1 auto;
        max-width: 45%;
      }

      .action-row {
        flex: 0 1 auto;
        justify-content: flex-end;
        min-width: 30%;
      }

      .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 1rem;
        justify-content: flex-start;

        .action-row {
          padding-left: 0;
        }

        .documentEditToggle {
          top: 2px;
        }
      }
    }
  }

  .documentEditToggle {
    margin-top: 0;
  }
}

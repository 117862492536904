@import '../abstracts/util';

@mixin table-responsive-base() {
  .table-responsive {
    padding: 0.25rem 1.25rem 1.25rem 1.25rem;
    display: block;
    overflow: auto;
    background: clr('white');
    border-radius: config('borderRadius');

    .table {
      .thead {
        overflow-y: auto;
        overflow-x: auto;
        font-size: 0.75rem;
        color: clr('font-muted');
      }

      .tbody {
        font-size: 0.95rem;
        overflow-y: auto;
        overflow-x: auto;
      }

      .tr {
        border-bottom: 1px solid clr('soft-stroke');
      }

      .th,
      .td {
        display: flex;
        align-items: center;
        overflow: hidden;
        // TODO: pass cell styling as props to table component
        min-height: 50px;

        &:not(:first-child) {
          // do not pad first cell for the RowExpander
          padding: 0.5rem 0 0.5rem 0;
        }

        &:not(:first-child):not(:last-child) {
          padding: 0 0.5rem 0 0.5rem;
        }

        .resizer {
          top: 0;
          right: 0;
          z-index: 1;
          width: 10px;
          height: 100%;
          position: absolute;
          touch-action: none;
        }

        .inset-branch {
          width: 100%;
          height: 50%;
          border-bottom-left-radius: 10px;
          border-left: 1px solid clr('soft-stroke');
          border-bottom: 1px solid clr('soft-stroke');
        }

        .inset-branch-end {
          width: 7px;
          height: 7px;
          border-radius: 100%;
          border: 1px solid clr('soft-stroke');
        }
      }
    }

    .pagination-footer {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 0 0 0;

      .pagination-progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.75rem;
      }
    }
  }
}

@import '../abstracts/util';

$transition: width cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.4s;

html,
body,
#root,
.dashboard {
  height: 100%;
  // 100vh should be avoided since it can cause issues on mobile screens
  // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
}

html,
body {
  // content-bg
  background-color: #f4f5f9;
}

@mixin layout-base() {
  .dashboard {
    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    color: clr(font-primary);
    overflow-y: hidden;
  }

  .sidebar {
    background-color: clr(sidebar-bg);
    border-top: 1px solid clr('soft-stroke');
    display: flex;

    button {
      svg {
        margin: 0;
      }
    }

    a {
      @extend .center;
      height: config(headerHeight);
      width: 100%;

      &.active,
      &:hover {
        background: clr('sidebar-active-bg');
      }

      .link-description {
        min-width: 77px;
      }
    }

    * {
      color: clr(sidebar-fg);
    }

    .svg-inline--fa {
      font-size: 1rem;
    }

    svg[class*='fa-terminal'] {
      font-size: 0.8125rem;
    }

    i > img {
      height: 1.4rem;
    }

    .sidebar-icon {
      height: 1rem;
      width: 3.125rem;
    }

    .fa-external-link-alt {
      path {
        fill: clr('stroke');
      }
    }
  }

  .content__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto; // On mobile content_main is responsible for y-scroll
  }

  .content__main-panel {
    flex: 1 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    order: 1;
  }

  .content__left-panel {
    flex: 1 0;
    order: 2;
  }

  .content__left-panel > div {
    // Generated by react-outlet
    height: 100%;
  }

  .footer {
    background-color: clr(footer-bg);
    display: none;
    padding: 1rem 0;
    margin-top: auto;
  }

  .scrollList {
    height: config(headerHeight) * 6.25;
    overflow-y: auto;
  }

  .page-action-bar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;

    h3 {
      margin-right: 1.5rem;
    }
  }

  .panel--redirect {
    background-color: #fff9;
    padding: 1rem;
    font-size: 1.5rem;
    border-radius: 2px;
  }
}

@mixin layout-xsmall() {
  .content {
    flex-direction: row;
  }

  .content__main-panel {
    width: 100%;
  }

  .content__left-panel {
    flex: 1 0;
  }

  .sidebar {
    border: 0;
    border-right: 1px solid clr('soft-stroke');
    display: block;
    height: 100%;
    width: 14rem;

    .svg-inline--fa,
    i > img {
      width: 3.125rem;
    }

    a {
      padding: 0.5rem 0;
      justify-content: left;

      & > span:first-child {
        text-align: center;
      }
    }
  }

  .content__main-panel {
    padding: 0 1.5rem;
  }
}

@mixin layout-small() {
  .content {
    flex: row;
  }

  .content__left-panel {
    flex: 1;
  }
}

@mixin layout-medium() {
  .footer {
    display: flex;
    padding: 1rem 0;
  }

  .content__main {
    flex-direction: row;
    overflow-y: hidden;
  }

  .content__main-panel {
    flex: initial;
    overflow-y: scroll; // On desktop content__main-panel is responsible for y-scroll
  }

  .content__left-panel {
    order: initial;
  }
}

@import '../abstracts/util';

@mixin loader-base() {
  .loader {
    @extend .center;
    width: 100%;
    height: 100%;
    padding: 4rem 1rem;
  }
}
